<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <template v-for="(item, i) in items">
        <div class="col-xxl-3 col-md-6 col-lg-4 mb-8" :key="i">
          <sc-stat :number="item.number" :title="item.title" :variant="item.variant" :icon-class="item.iconClass" />
        </div>
      </template>
    </div>

    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ScStat from '../content/widgets/sc-stat.vue'

import EventServices from '../../core/services/Event'

export default {
  name: 'dashboard',
  data() {
    return {
      items: [
        {
          variant: 'danger',
          number: 0,
          title: 'Pending Events',
          iconClass: 'far fa-chart-bar',
        },
        {
          variant: 'info',
          number: 0,
          title: 'Pending Users',
          iconClass: 'fa fa-comments',
        },
        {
          variant: 'success',
          number: 0,
          title: 'Active Events',
          iconClass: 'fas fa-shopping-cart',
        },
        {
          variant: 'primary',
          number: 0,
          title: 'Active Users',
          iconClass: 'fa fa-globe',
        },
        {
          variant: 'info',
          number: 0,
          title: 'Pending Organizer Events',
          iconClass: 'fa fa-comments',
        },
        {
          variant: 'danger',
          number: 0,
          title: 'Pending Organizers',
          iconClass: 'far fa-chart-bar',
        },
        {
          variant: 'primary',
          number: 0,
          title: 'Active Organizer Events',
          iconClass: 'fa fa-globe',
        },
        {
          variant: 'success',
          number: 0,
          title: 'Active Organizers',
          iconClass: 'fas fa-shopping-cart',
        },
      ],
    }
  },
  components: {
    ScStat,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Home ' }])
    this.getEvents()
  },
  methods: {
    async getEvents() {
      const pendingEvents = (await EventServices.getEventByStatus('pending')).data
      const activeEvents = (await EventServices.getEventByStatus('active')).data.length
      const pendingItem = this.items.find(q => q.title == 'Pending Events')
      const activeItem = this.items.find(q => q.title == 'Active Events')

      pendingItem.number = pendingEvents.length
      activeItem.number = activeEvents
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event)
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event)
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]')
      const links = tab.querySelectorAll('.nav-link')
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove('active')
      }

      // set current active tab
      event.target.classList.add('active')

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute('data-tab'))
    },
  },
}
</script>
