<template>
  <b-card v-bind:bg-variant="variant">
    <i v-bind:class="`${iconClass} text-light fa-8x`"></i>
    <h1 class="text-right text-white">{{ number }}</h1>
    <h5 class="text-right text-white">{{ title }}</h5>
  </b-card>
</template>
<style scoped>
i {
  opacity: 0.2;
  margin-left: 2px;
  position: absolute;
  bottom: -20px;
  left: -20px;
}
</style>
<script>
export default {
  name: 'sc-stat',
  props: {
    variant: {
      type: String,
      default: 'success',
    },
    number: {
      type: Number,
      default: 100,
    },
    title: {
      type: String,
      default: 'Numbers',
    },
    iconClass: {
      type: String,
      default: 'fas fa-chart-bar',
    },
  },
}
</script>
